import { useState, Fragment } from "react";

export default function NoteDuration({ noteDuration, onChange }) {
	const onMinusClick = () => {
		if (noteDuration > 1) onChange(noteDuration - 1);
	};

	const onPlusClick = () => {
		if (noteDuration < 10) onChange(noteDuration + 1);
	};

	const onNoteDurationChange = (e) => {
		onChange(parseInt(e.target.value));
	};

	return (
		<Fragment>
			<h2>NOTE DURATION</h2>
			<div className="noteDurationControl">
				<button className="button" onClick={onMinusClick}>
					-
				</button>
				<input
					type="number"
					className="noteDurationControl__input"
					value={noteDuration}
					onChange={onNoteDurationChange}
					min={1}
					max={10}
					step={1}
				/>
				<button className="button" onClick={onPlusClick}>
					+
				</button>
			</div>
		</Fragment>
	);
}
