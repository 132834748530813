export default function BeatIndicator({ beat }) {
	return (
		<div className="beatIndicator">
			<div
				className={`beatIndicator__beat ${
					beat === 1 ? "beatIndicator__beat--active" : null
				}`}
			></div>
			<div
				className={`beatIndicator__beat ${
					beat === 2 ? "beatIndicator__beat--active" : null
				}`}
			></div>
			<div
				className={`beatIndicator__beat ${
					beat === 3 ? "beatIndicator__beat--active" : null
				}`}
			></div>
			<div
				className={`beatIndicator__beat ${
					beat === 4 ? "beatIndicator__beat--active" : null
				}`}
			></div>
		</div>
	);
}
