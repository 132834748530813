import { useState, useEffect, Fragment } from "react";
import {
	NOTES,
	getRandomNote,
	getNumberOfSemitonesBetweenTwoNotes,
	getIntervalByNumberOfSemitones,
	getAvailableNoteSigns,
	displayNote,
} from "../constants";
import BeatIndicator from "../components/BeatIndicator";
import BarIndicator from "../components/BarIndicator";
import SlidyPanel from "../components/SlidyPanel";
import TempoControl from "../components/TempoControl";
import VolumeControl from "../components/VolumeControl";
import SubdivisionControl from "../components/SubdivisionControl";
import NoteTypeControl from "../components/NoteTypeControl";
import NoteControl from "../components/NoteControl";
import NoteDurationControl from "../components/NoteDurationControl";
import TonalMetronomeControl from "../components/TonalMetronomeControl";
import Metronome from "../Metronome";

function BottomBar({
	onBeat,
	countdown,
	onStart,
	onStop,
	tempo,
	frequency,
	volume,
	subdivision,
}) {
	return (
		<section className="bottomBar">
			<Metronome
				onBeat={onBeat}
				getReady={countdown > 0}
				onMetronomeStart={onStart}
				onMetronomeEnd={onStop}
				tempo={tempo}
				frequency={frequency}
				volume={volume}
				noteResolution={subdivision}
			/>
		</section>
	);
}

export default function RandomNote({
	countdown,
	currentBar,
	currentBeat,
	isRunning,
	tempo,
	setTempo,
	onBeat,
	onStart,
	onStop,
	volume,
	setVolume,
	subdivision,
	setSubdivision,
	noteType,
	setNoteType,
	progressiveTempoIncrease,
	setProgressiveTempoIncrease,
	tonalMetronome,
	setTonalMetronome,
}) {
	// Array of strings representing note signs
	// for example: ["C", "C#", "D"]
	const [selectedNotes, setSelectedNotes] = useState([
		...getAvailableNoteSigns(),
	]);

	const [currentNote, setCurrentNote] = useState(null);

	const [nextNote, setNextNote] = useState(null);

	//	How many bars have to pass for us to change the note
	const [noteDuration, setNoteDuration] = useState(1);

	//	Both are related to the possibility of selecting a scale
	const [selectedRoot, setSelectedRoot] = useState("");
	const [selectedScaleType, setSelectedScaleType] = useState("");

	//	Set the initial values in case no notes have been set
	useEffect(() => {
		if (!currentNote && !nextNote) {
			const newCurrentNote = getRandomNote(selectedNotes);
			const newNextNote = getRandomNote(
				selectedNotes,
				newCurrentNote.sign
			);
			setCurrentNote(newCurrentNote);
			setNextNote(newNextNote);
		}
	}, [currentBeat, countdown, selectedNotes]);

	//	This effect makes sure that a new current note and next note is set
	//	on the clean up beat, while we no longer use countdown and it is not
	//	the first bar (we have already set the value for the first bar)
	useEffect(() => {
		if (
			currentBeat === 5 && //	clean-up beat
			countdown === 0 && //	we are not counting down
			currentBar !== 0 && //	the metronome is running
			currentBar % noteDuration === 0 //	the right amount of bars has passed
		) {
			setCurrentNote(nextNote);
			setNextNote(getRandomNote(selectedNotes, nextNote.sign));
		}
	}, [currentBeat, countdown, currentBar, selectedNotes, noteDuration]);

	const handleSelectedNoteToggle = (noteSign) => {
		const indexOfNote = selectedNotes.indexOf(noteSign);
		//	removing a note from selected notes
		if (indexOfNote > -1) {
			//	the minimum of selected notes is 2
			if (selectedNotes.length === 2) return;
			setSelectedNotes([
				...selectedNotes.filter((sNote) => sNote !== noteSign),
			]);
			//	in case the removed note is either the current note
			//	or the next note, we need to regenerate it
			if (currentNote.sign === noteSign)
				setCurrentNote(getRandomNote(selectedNotes, nextNote.sign));
			if (nextNote.sign === noteSign)
				setNextNote(getRandomNote(selectedNotes, currentNote.sign));
		} else {
			setSelectedNotes([...selectedNotes, noteSign]);
		}
	};

	const handleScaleSelection = (noteSigns) => {
		setSelectedNotes(noteSigns);

		//	because we have to ensure that the currently generated
		//	notes are from the scale that is about to be set.
		if (noteSigns.indexOf(currentNote.sign) === -1) {
			setCurrentNote(getRandomNote(noteSigns, nextNote.sign));
		}
		if (noteSigns.indexOf(nextNote.sign) === -1) {
			setNextNote(getRandomNote(noteSigns, currentNote.sign));
		}
	};

	const isScaleSelected = () =>
		selectedRoot !== "" && selectedScaleType !== "";

	const getScaleDegreeLabel = (scaleDegree) => {
		if (scaleDegree === 1) return "^1";
		else if (scaleDegree === 2) return "^2";
		else if (scaleDegree === 3) return "^3";
		else if (scaleDegree === 4) return "^4";
		else if (scaleDegree === 5) return "^5";
		else if (scaleDegree === 6) return "^6";
		else if (scaleDegree === 7) return "^7";
		else return `${scaleDegree}th`;
	};

	//	Because we render the scale degree next to the note
	//	it would look off when centered. That's why we need to
	//	compensate.
	const getCompensationForScaleDegree = () => {
		if (isScaleSelected()) return { marginRight: "-28px" };
		else {
			return {};
		}
	};

	const getNoteDurationBarCount = (currentBar, noteDuration) => {
		const cBar = currentBar < 0 ? 0 : currentBar;
		if (cBar > 0 && Number.isInteger(cBar / noteDuration))
			return noteDuration;
		else return cBar % noteDuration;
	};

	return (
		<Fragment>
			<div className="randomNote">
				<SlidyPanel>
					<TempoControl
						tempo={tempo}
						setTempo={setTempo}
						progressivelyIncrease={progressiveTempoIncrease}
						onProgressivelyIncreaseClick={
							setProgressiveTempoIncrease
						}
					/>
					<VolumeControl volume={volume} onChange={setVolume} />
					<TonalMetronomeControl
						onClick={setTonalMetronome}
						tonalMetronome={tonalMetronome}
					/>
					<NoteDurationControl
						noteDuration={noteDuration}
						onChange={setNoteDuration}
					/>
					<SubdivisionControl
						subdivision={subdivision}
						setSubdivision={setSubdivision}
					/>
					<h2>NOTE SELECTOR</h2>
					<NoteTypeControl
						noteType={noteType}
						setNoteType={setNoteType}
					/>
					<NoteControl
						selectedRoot={selectedRoot}
						selectedScaleType={selectedScaleType}
						selectedNotes={selectedNotes}
						availableNotes={getAvailableNoteSigns()}
						noteType={noteType}
						onNoteClick={handleSelectedNoteToggle}
						onScaleSelection={handleScaleSelection}
						onRootChange={setSelectedRoot}
						onScaleTypeChange={setSelectedScaleType}
					/>
					<div className="about">
						<p>
							Feel free to{" "}
							<a href="mailto:bold.flinch-0w@icloud.com">
								contact me
							</a>{" "}
							in case you have any suggestions or questions.
						</p>
						<p>
							Background picture by Leon Schlüß from{" "}
							<a
								href="https://unsplash.com/photos/m-qZ3y9PQHM"
								target="_blank"
							>
								Unsplash
							</a>
							.
						</p>
						<p>
							In case you want to support me, please give a listen
							to my project{" "}
							<a
								href="https://www.youtube.com/watch?v=WtT77DRAbdE&list=PLdFN9CbP01bUD510XF8CRf2x6O_UHwVEI"
								target="_blank"
							>
								The Wandering
							</a>{" "}
							.
						</p>
					</div>
				</SlidyPanel>
				<div className="randomNote__body">
					<div className="currentNote">
						<p
							className="currentNote__sign"
							style={getCompensationForScaleDegree()}
						>
							<strong>
								{displayNote(currentNote?.sign, noteType)}
							</strong>
							{isScaleSelected() ? (
								<span className="currentNote__scaleDegree">
									{getScaleDegreeLabel(
										selectedNotes.indexOf(
											currentNote?.sign
										) + 1
									)}
								</span>
							) : null}
						</p>
					</div>
					<p>|</p>
					{noteDuration > 1 && (
						<Fragment>
							<p className="noteDutation">{`${getNoteDurationBarCount(
								currentBar,
								noteDuration
							)}/${noteDuration}`}</p>
							<p>|</p>
						</Fragment>
					)}
					{currentNote && nextNote && (
						<p className="interval">
							<i>
								{
									getIntervalByNumberOfSemitones(
										getNumberOfSemitonesBetweenTwoNotes(
											currentNote.sign,
											nextNote.sign
										)
									).name
								}
							</i>
						</p>
					)}
					<p>↓</p>
					<div className="nextNote">
						<p style={getCompensationForScaleDegree()}>
							<span className="nextNote__sign">
								{displayNote(nextNote?.sign, noteType)}
							</span>
							{isScaleSelected() ? (
								<span className="nextNote__scaleDegree">
									{getScaleDegreeLabel(
										selectedNotes.indexOf(nextNote?.sign) +
											1
									)}
								</span>
							) : null}
						</p>
					</div>
				</div>
				<div className="randomNote__footer">
					<p>{tempo}BPM</p>
					<BeatIndicator beat={currentBeat} />
					<BarIndicator
						isRunning={isRunning}
						currentBar={currentBar}
						countdown={countdown}
					/>
				</div>
			</div>
			<BottomBar
				onBeat={onBeat}
				countdown={countdown}
				onStart={onStart}
				onStop={onStop}
				tempo={tempo}
				frequency={tonalMetronome ? currentNote?.frequency : undefined}
				volume={volume}
				subdivision={subdivision}
			/>
		</Fragment>
	);
}
