import { useState, Fragment } from "react";

export default function VolumeControl({ volume, onChange }) {
	const onMinusClick = () => {
		const newValue = parseFloat(volume - 0.1);
		onChange(parseFloat(newValue.toFixed(1)));
	};

	const onPlusClick = () => {
		const newValue = parseFloat(volume + 0.1);
		onChange(parseFloat(newValue.toFixed(1)));
	};

	const onVolumeChange = (e) => {
		const newValue = parseFloat(e.target.value);
		onChange(parseFloat(newValue.toFixed(1)));
	};

	return (
		<Fragment>
			<h2>VOLUME</h2>
			<div className="volumeControl">
				<button
					className="button"
					disabled={volume === 0.1}
					onClick={onMinusClick}
				>
					-
				</button>
				<input
					type="number"
					className="volumeControl__input"
					value={volume}
					onChange={onVolumeChange}
					min="0.1"
					max="0.9"
					step="0.1"
				/>
				<button
					className="button"
					disabled={volume === 0.9}
					onClick={onPlusClick}
				>
					+
				</button>
			</div>
		</Fragment>
	);
}
