/* eslint import/no-webpack-loader-syntax: off */
import TickWorker from "worker-loader!./tick.worker.js";

import React, { Component } from "react";
//otherwise it would try to load an ES6 module and fail when building
import NoSleep from "nosleep.js/dist/NoSleep";
import Button from "../components/lib/Button";

let noSleep = new NoSleep();

var AudioContext =
	window.AudioContext || // Default
	window.webkitAudioContext || // Safari and old versions of Chrome
	false;

if (!AudioContext) {
	alert(
		"Sorry but it seems like your browser does not supports features neccesary to run this website"
	);
}

const GET_READY_FREQUENCY = 830.61;
const FIRST_BEAT_DEFAULT_FREQUENCY = 880;
const DEFAULT_QUARTER_NOTE_FREQUENCY = 440;

/**
 *
 */
let UNLOCKED_CONTEXT = false;

/**
 *
 */
const AUDIO_CONTEXT = new AudioContext();

/**
 *
 */
class Metronome extends Component {
	/**
	 *
	 */
	constructor(args) {
		super(args);
		this.state = {
			worker: null,
			isRunning: false,
			notesInQueue: [],
			startTime: null,
		};
		this.startTick = this.startTick.bind(this);
		this.stopTick = this.stopTick.bind(this);
		this.onTick = this.onTick.bind(this);
		this.scheduler = this.scheduler.bind(this);
		this.nextNote = this.nextNote.bind(this);
		this.scheduleNote = this.scheduleNote.bind(this);
		this.handleSpacePress = this.handleSpacePress.bind(this);
	}

	nextNoteTime: AUDIO_CONTEXT.currentTime;

	current16thNote: 0;

	/**
	 *
	 */
	scheduleNote(beatNumber, time) {
		if (beatNumber === 15) {
			this.props.onBeat(5);
		}
		const {
			noteResolution,
			noteLength,
			onBeat,
			barLimit,
			currentBar,
			volume,
			getReady,
			frequency,
		} = this.props;
		const { notesInQueue } = this.state;
		// push the note on the queue, even if we're not playing.
		this.setState({
			notesInQueue: [...notesInQueue, { note: beatNumber, time }],
		});

		if (noteResolution == 1 && beatNumber % 2) return; // we're not playing non-8th 16th notes
		if (noteResolution == 2 && beatNumber % 4) return; // we're not playing non-quarter 8th notes

		var osc = AUDIO_CONTEXT.createOscillator();
		var gainNode = AUDIO_CONTEXT.createGain();
		osc.connect(gainNode);
		gainNode.connect(AUDIO_CONTEXT.destination);
		gainNode.gain.value = volume;
		//osc.connect( AUDIO_CONTEXT.destination )
		if (beatNumber % 16 === 0) {
			if (onBeat) onBeat(1);
			if (currentBar === barLimit && barLimit > 0) this.stopTick();
			osc.frequency.value = getReady
				? GET_READY_FREQUENCY
				: FIRST_BEAT_DEFAULT_FREQUENCY;
		} else if (beatNumber % 4 === 0) {
			if (onBeat) onBeat(beatNumber / 4 + 1);
			osc.frequency.value = getReady ? GET_READY_FREQUENCY : frequency;
		} else {
			osc.frequency.value = getReady
				? GET_READY_FREQUENCY
				: frequency / 2;
		}

		osc.start(time);
		osc.stop(time + noteLength);
	}

	/**
	 *
	 */
	nextNote() {
		const { tempo } = this.props;
		const secondsPerBeat = 60.0 / tempo;
		this.nextNoteTime = this.nextNoteTime + 0.25 * secondsPerBeat;
		this.current16thNote =
			this.current16thNote + 1 < 16 ? this.current16thNote + 1 : 0;
	}

	/**
	 *
	 */
	scheduler() {
		const { scheduleAheadTime } = this.props;
		while (
			this.nextNoteTime <
			AUDIO_CONTEXT.currentTime + scheduleAheadTime
		) {
			this.scheduleNote(this.current16thNote, this.nextNoteTime);
			this.nextNote();
		}
	}

	/**
	 *
	 */
	onTick(e) {
		if (e.data == "tick") this.scheduler();
		else console.log("message: " + e.data);
	}

	/*
	 *
	 */
	componentDidMount() {
		const { lookahead } = this.props;
		const worker = new TickWorker();
		worker.onmessage = this.onTick;
		this.setState({ worker });
		worker.postMessage({ interval: lookahead });
		window.addEventListener("keydown", this.handleSpacePress);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.handleSpacePress);
	}

	/**
	 *
	 */
	handleSpacePress(e) {
		//otherwise it would be triggered twice
		if (
			document.activeElement &&
			document.activeElement.classList.contains(
				"metronome__button--startStop"
			)
		)
			return;
		const { isRunning, worker } = this.state;
		if (e.keyCode == 0 || e.keyCode == 32) {
			if (isRunning) this.stopTick();
			else this.startTick();
		}
	}

	/**
	 *
	 */
	startTick() {
		if (this.props.onMetronomeStart) {
			this.props.onMetronomeStart();
		}
		//so your phone screen does not lock after few seconds
		noSleep.enable();
		// Magical trick that makes it work on mobile
		if (!UNLOCKED_CONTEXT) {
			var buffer = AUDIO_CONTEXT.createBuffer(1, 1, 22050);
			var node = AUDIO_CONTEXT.createBufferSource();
			node.buffer = buffer;
			node.start(0);
			UNLOCKED_CONTEXT = true;
		}
		/** **/
		if (!this.state.isRunning) {
			this.nextNoteTime = AUDIO_CONTEXT.currentTime + 0.1;
			this.current16thNote = 0;
			this.setState({ isRunning: true });
			this.state.worker.postMessage("start");
		}
	}

	/**
	 *
	 */
	stopTick() {
		noSleep.disable();
		if (this.state.isRunning) {
			if (this.props.onMetronomeEnd) this.props.onMetronomeEnd();
			this.state.worker.postMessage("stop");
			this.setState({ isRunning: false });
		}
	}

	/**
	 *
	 */
	render() {
		const { isRunning } = this.state;
		return (
			<Button
				onClick={isRunning ? this.stopTick : this.startTick}
				label={isRunning ? "stop" : "start"}
			/>
		);
	}
}

Metronome.defaultProps = {
	tempo: 120.0,
	lookahead: 25.0,
	scheduleAheadTime: 0.1,
	noteResolution: 2,
	noteLength: 0.05,
	volume: 0.1,
	getReady: false, //affect the frequency of the tick
	frequency: DEFAULT_QUARTER_NOTE_FREQUENCY,
	onBeat: (beat) => {
		console.log(beat);
	},
};

export default Metronome;
