import { useState, Fragment } from "react";

export default function TempoControl({
	tempo,
	setTempo,
	progressivelyIncrease,
	onProgressivelyIncreaseClick,
}) {
	const onMinusClick = () => {
		setTempo(tempo - 1);
	};

	const onPlusClick = () => {
		setTempo(tempo + 1);
	};

	const onTempoChange = (e) => {
		setTempo(parseInt(e.target.value));
	};

	const onProgIncreaseClick = (e) => {
		onProgressivelyIncreaseClick(!progressivelyIncrease);
	};

	return (
		<Fragment>
			<h2>TEMPO</h2>
			<div className="tempoControl">
				<button className="button" onClick={onMinusClick}>
					-
				</button>
				<input
					type="number"
					className="tempoControl__input"
					value={tempo}
					onChange={onTempoChange}
					min={1}
					max={500}
				/>
				<button className="button" onClick={onPlusClick}>
					+
				</button>
			</div>
			<label className="tempoControl__progressive">
				<input
					label="Progressive tempo increase"
					type="checkbox"
					value={progressivelyIncrease}
					checked={progressivelyIncrease}
					onChange={onProgIncreaseClick}
				/>
				increase by 1 every 10th bar
			</label>
		</Fragment>
	);
}
