import { useState } from "react";
import {
	displayNote,
	getAvailableNoteSigns,
	getAvailableScaleTypes,
	getNotesInScale,
} from "../../constants";

export default function NoteControl({
	selectedNotes,
	availableNotes,
	onNoteClick,
	noteType,
	selectedRoot,
	selectedScaleType,
	onRootChange,
	onScaleTypeChange,
	onScaleSelection,
}) {
	const handleNoteClick = (note) => (e) => {
		onNoteClick(note);
		//	Because when we select a note and there is currently
		//	a selected scale, we want to reset the selected scale
		//	as it not longer counts anymore
		if (selectedRoot !== "") onRootChange("");
		if (selectedScaleType !== "") onScaleTypeChange("");
	};

	const handleScaleRootChange = (e) => {
		const newRoot = e.target.value;
		onRootChange(newRoot);
		setScale(newRoot, selectedScaleType);
	};

	const handleScaleTypeChange = (e) => {
		const newScaleType = e.target.value;
		onScaleTypeChange(newScaleType);
		setScale(selectedRoot, newScaleType);
	};

	const setScale = (root, scaleType) => {
		if (root && root !== "" && scaleType && scaleType !== "") {
			onScaleSelection(getNotesInScale(root, scaleType));
		}
	};

	const isAvailableNoteSelected = (availableNote, selectedNotes) => {
		if (selectedNotes.indexOf(availableNote) > -1) return true;
		else return false;
	};

	const availableScaleRoots = getAvailableNoteSigns();
	const availableScaleTypes = getAvailableScaleTypes();

	return (
		<form>
			<p>Pick a scale:</p>
			<div className="scalePicker">
				<select value={selectedRoot} onChange={handleScaleRootChange}>
					<option key="" value=""></option>
					{availableScaleRoots.map((root) => (
						<option key={root} value={root}>
							{root}
						</option>
					))}
				</select>
				<select
					value={selectedScaleType}
					onChange={handleScaleTypeChange}
					className="scaleTypeSelect"
				>
					<option key="" value=""></option>
					{availableScaleTypes.map((type) => (
						<option key={type.sign} value={type.sign}>
							{type.title}
						</option>
					))}
				</select>
			</div>
			<p>or pick individual notes:</p>
			<div className="noteControl">
				{availableNotes.map((note) => (
					<label key={note} className="noteControl__note">
						<input
							label={note}
							type="checkbox"
							value={note}
							checked={isAvailableNoteSelected(
								note,
								selectedNotes
							)}
							onChange={handleNoteClick(note)}
						/>
						{displayNote(note, noteType)}
					</label>
				))}
			</div>
		</form>
	);
}
