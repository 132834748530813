import { useState } from "react";

export default function SlidyPanel({ children }) {
	const [isExpanded, setIsExpanded] = useState(false);
	const left = isExpanded ? 0 : -300;

	const onHandlerClick = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className="slidyPanel" style={{ left: `${left}px` }}>
			<button className="slidyPanel__handler" onClick={onHandlerClick}>
				☰
			</button>
			<div className="slidyPanel__content">{children}</div>
		</div>
	);
}
