import { Fragment } from "react";
import { NOTE_TYPES } from "../../constants";

export default function NoteTypeControl({ noteType, setNoteType }) {
	const options = Object.values(NOTE_TYPES);
	const onNoteTypeChange = (option) => (e) => {
		setNoteType(option.sign);
	};
	return (
		<Fragment>
			<p>Display notes as:</p>
			<form className="noteTypeControl">
				{options.map((option) => {
					return (
						<div
							key={option.title}
							className="noteTypeControl__noteType"
						>
							<label htmlFor={`note-type-${option.title}`}>
								<input
									id={`note-type-${option.title}`}
									type="radio"
									value={option.title}
									name="notedivision"
									checked={noteType === option.sign}
									onChange={onNoteTypeChange(option)}
								/>
								{option.title}
							</label>
						</div>
					);
				})}
			</form>
		</Fragment>
	);
}
