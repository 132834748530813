export const NOTE_TYPES = {
  SHARP: { sign: "#", title: "sharp" },
  FLAT: { sign: "♭", title: "flat" },
};

export const SUBDIVISION = {
  FOUR: { title: "4th", noteResolution: 2 },
  EIGHT: { title: "8th", noteResolution: 1 },
  SIXTEEN: { title: "16th", noteResolution: 0 },
};

export const SCALE_TYPES = {
  MAJOR: { title: "major", sign: "MAJOR", signature: [2, 2, 1, 2, 2, 2, 1] },
  MINOR: { title: "minor", sign: "MINOR", signature: [2, 1, 2, 2, 1, 2, 2] },
  HARMONIC_MINOR: {
    title: "harmonic minor",
    sign: "HARMONIC_MINOR",
    signature: [2, 1, 2, 2, 1, 3, 1],
  },
  PHRYGIAN_DOMINANT: {
    title: "phrygian dominant",
    sign: "PHRYGIAN_DOMINANT",
    signature: [1, 3, 1, 2, 1, 2, 2],
  },
  DORIAN_SHARP_FOURTH: {
    title: "dorian #4",
    sign: "DORIAN_SHARP_FOURTH",
    signature: [2, 1, 3, 1, 2, 1, 2],
  },
};

export const NOTES = [
  {
    sign: "C",
    displaySign: { sharp: "C", flat: "C" },
    octave: 4,
    frequency: 261.63,
  },
  {
    sign: "C#",
    displaySign: { sharp: "C#", flat: "D♭" },
    octave: 4,
    frequency: 277.18,
  },
  {
    sign: "D",
    displaySign: { sharp: "D", flat: "D" },
    octave: 4,
    frequency: 293.66,
  },
  {
    sign: "D#",
    displaySign: { sharp: "D#", flat: "E♭" },
    octave: 4,
    frequency: 311.13,
  },
  {
    sign: "E",
    displaySign: { sharp: "E", flat: "E" },
    octave: 4,
    frequency: 329.63,
  },
  {
    sign: "F",
    displaySign: { sharp: "F", flat: "F" },
    octave: 4,
    frequency: 349.23,
  },
  {
    sign: "F#",
    displaySign: { sharp: "F#", flat: "G♭" },
    octave: 4,
    frequency: 369.99,
  },
  {
    sign: "G",
    displaySign: { sharp: "G", flat: "G" },
    octave: 4,
    frequency: 392.0,
  },
  {
    sign: "G#",
    displaySign: { sharp: "G#", flat: "A♭" },
    octave: 4,
    frequency: 415.3,
  },
  {
    sign: "A",
    displaySign: { sharp: "A", flat: "A" },
    octave: 4,
    frequency: 440.0,
  },
  {
    sign: "A#",
    displaySign: { sharp: "A#", flat: "B♭" },
    octave: 4,
    frequency: 466.16,
  },
  {
    sign: "B",
    displaySign: { sharp: "B", flat: "B" },
    octave: 4,
    frequency: 493.88,
  },
];

export const getAvailableNoteSigns = () => {
  return NOTES.map((n) => n.sign);
};

export const getAvailableScaleTypes = () => {
  return Object.values(SCALE_TYPES);
};

export const getNotesInScale = (noteSign, scaleTypeSign) => {
  const scaleType = SCALE_TYPES[scaleTypeSign];
  const notes = [...NOTES.map((n) => n.sign), ...NOTES.map((n) => n.sign)];
  const indexOfNote = notes.indexOf(noteSign);
  const scale = [noteSign];
  scaleType.signature.reduce((agg, cur) => {
    agg += cur;
    scale.push(notes[indexOfNote + agg]);
    return agg;
  }, 0);
  //  otherwise we would have the root note both at the beginning
  //  and at the end of the array
  scale.pop();
  return scale;
};

export const intervals = [
  { numOfSemitones: 0, name: "unison", sign: "uni" },
  { numOfSemitones: 1, name: "minor second", sign: "m2nd" },
  { numOfSemitones: 2, name: "major second", sign: "M2nd" },
  { numOfSemitones: 3, name: "minor third", sign: "m3rd" },
  { numOfSemitones: 4, name: "major third", sign: "M3rd" },
  { numOfSemitones: 5, name: "perfect fourth", sign: "p4th" },
  { numOfSemitones: 6, name: "tritone", sign: "tri" },
  { numOfSemitones: 7, name: "perfect fifth", sign: "p5th" },
  { numOfSemitones: 8, name: "minor sixth", sign: "m6th" },
  { numOfSemitones: 9, name: "major sixth", sign: "M6th" },
  { numOfSemitones: 10, name: "minor seventh", sign: "m7th" },
  { numOfSemitones: 11, name: "major seventh", sign: "M7th" },
  { numOfSemitones: 12, name: "octave", sign: "oct" },
];

export const EXERCICE_TYPES = {
  RANDOM_NOTE: "RANDOM_NOTE",
  RANDOM_CHORD: "RANDOM_CHORD",
  RANDOM_MODE: "RANDOM_MODE",
};

export const getIntervalByNumberOfSemitones = (semitones) => {
  const interal = intervals.find((i) => i.numOfSemitones === semitones);
  return interal;
};

/**
 * @param noteSign {String}... for example "C#"
 * @param noteType {String}... for example "#"
 */
export const displayNote = (noteSign, noteType) => {
  if (!noteSign || !noteType) return "-";
  const noteObj = NOTES.find((note) => note.sign === noteSign);
  const noteTypeObj = Object.values(NOTE_TYPES).find(
    (nType) => nType.sign === noteType
  );
  return noteObj.displaySign[noteTypeObj.title];
};

/**
 * @param notes {Array<number, string>}
 * @param skipNotes {String}
 */
export const getRandomNote = (noteSigns, skipNote) => {
  const min = 0,
    max = noteSigns.length - 1;
  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
  if (noteSigns[randomNumber] === skipNote)
    return getRandomNote(noteSigns, skipNote);
  else return NOTES.find((n) => n.sign === noteSigns[randomNumber]);
};

export const getNumberOfSemitonesBetweenTwoNotes = (
  firstNote,
  secondNote,
  notes = NOTES
) => {
  const serializedNotes = notes.map((note) => note.sign);
  const indexOfFirstNote = serializedNotes.indexOf(firstNote);
  const restructNotes = [
    ...serializedNotes.slice(indexOfFirstNote),
    ...serializedNotes.slice(0, indexOfFirstNote),
  ];
  const numOfSemitones = restructNotes.indexOf(secondNote);
  return numOfSemitones;
};
