export default function BarIndicator({
	isRunning,
	currentBar,
	maxBar = "∞",
	countdown,
}) {
	const getTitle = () => {
		if (!isRunning)
			return "Press Space or click on the start button below to start...";
		else if (countdown > 0) return `Get ready! We start in ${countdown}...`;
		else return `${currentBar}/${maxBar}`;
	};

	return <div className="barIndicator">{getTitle()}</div>;
}
