import { Fragment } from "react";
import { SUBDIVISION } from "../../constants";

export default function SubdivisionControl({ subdivision, setSubdivision }) {
	const options = Object.values(SUBDIVISION);
	const onNoteResolutionChange = (option) => (e) => {
		setSubdivision(option.noteResolution);
	};
	return (
		<Fragment>
			<h2>SUBDIVISION</h2>
			<form className="subdivisionControl">
				{options.map((option) => {
					return (
						<div
							key={option.noteResolution}
							className="subdivisionControl__subdivision"
						>
							<label
								htmlFor={`subdivision-${option.noteResolution}`}
							>
								<input
									id={`subdivision-${option.noteResolution}`}
									type="radio"
									value={option.noteResolution}
									name="notedivision"
									checked={
										subdivision === option.noteResolution
									}
									onChange={onNoteResolutionChange(option)}
								/>
								{option.title}
							</label>
						</div>
					);
				})}
			</form>
		</Fragment>
	);
}
