export default function TonalMetronomeControl({
	tonalMetronome = false,
	onClick,
}) {
	const handleClick = () => {
		onClick(!tonalMetronome);
	};

	return (
		<label className="tonalMetronome">
			<input
				label="Tonal metronome"
				type="checkbox"
				value={tonalMetronome}
				checked={tonalMetronome}
				onChange={handleClick}
			/>
			Tonal metronome
		</label>
	);
}
