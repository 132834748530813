import { useState, Fragment } from "react";
import RandomNote from "./RandomNote";

const NUMBER_OF_BARS_OF_COUNTDOWN = 2;
const NUMBER_OF_BEATS_IN_COUNTDOWN_BAR = 4;
const DEFAULT_COUNTDOWN_VALUE =
  NUMBER_OF_BARS_OF_COUNTDOWN * NUMBER_OF_BEATS_IN_COUNTDOWN_BAR;
const DEFAULT_CURRURENT_BAR = -NUMBER_OF_BARS_OF_COUNTDOWN;
const DEFAULT_BEAT = 0;
const DEFAULT_TEMPO = 120;
const DEFAULT_VOLUME = 0.2;

function App() {
  //  Is the metronome running?
  const [isRunning, setIsRunning] = useState(false);

  //  Keeps track of the current beat within a bar.
  //  The valid value is 1, 2, 3, 4 and 5 while 5 is the clean-up beat
  const [currentBeat, setCurrentBeat] = useState(DEFAULT_BEAT);

  //  Turn on if you want the beep to use the frequency of the
  //  current note
  const [tonalMetronome, setTonalMetronome] = useState(false);

  //  countdown expresses the number of beats the countdown
  //  to starting the exercise has. 8 = 2 bars of count down.
  const [countdown, setCountdown] = useState(DEFAULT_COUNTDOWN_VALUE);

  //  - 2 because the current countdown has 2 bars
  const [currentBar, setBar] = useState(DEFAULT_CURRURENT_BAR);

  //  sets the default tempo
  const [tempo, setTempo] = useState(DEFAULT_TEMPO);

  //  adds the posibility to progressively increase the tempo
  //  after certain bars by certain bpm
  const [progressiveTempoIncrease, setProgressiveTempoIncrease] =
    useState(false);

  //  Default tempo to come back to when the user uses progressive tempo increase
  //  and stops the metronome
  const [originalTempo, setOriginalTempo] = useState(DEFAULT_TEMPO);

  //  sets the default volume
  const [volume, setVolume] = useState(DEFAULT_VOLUME);

  //  sets the defaut note subdivision
  const [subdivision, setSubdivision] = useState(2);

  //  sets the dafault note type
  const [noteType, setNoteType] = useState("#");

  const onProgressiveTempoIncreaseChange = (newValue) => {
    //  because if this feature has been turned on
    //  we want to store the current tempo
    //  so we can come back to it once the metronome
    //  is turned off
    if (newValue) {
      setOriginalTempo(tempo);
    }
    setProgressiveTempoIncrease(newValue);
  };

  const onBeat = (beat) => {
    setCurrentBeat(beat);

    // Because we want to lower the countdown on all but
    // the clean-up beat (aka the fifth beat)
    if (countdown && beat !== 5) {
      setCountdown(countdown - 1);
    }

    //  Every time we hit the first beat of a bar,
    //  update the bar counter
    if (beat === 1) {
      const newBarValue = currentBar + 1;
      setBar(newBarValue);

      //  Check for progressive tempo increase and if true
      //  increase the tempo with the new bar number
      if (
        progressiveTempoIncrease &&
        newBarValue > 0 &&
        newBarValue % 10 === 0
      ) {
        setTempo(tempo + 1);
      }
    }
  };

  const onStart = () => {
    setIsRunning(true);
  };

  // Reset to default values when the user stops
  // the metronome
  const onStop = () => {
    setIsRunning(false);
    setCurrentBeat(DEFAULT_BEAT);
    setCountdown(DEFAULT_COUNTDOWN_VALUE);
    setBar(DEFAULT_CURRURENT_BAR);
    //  If the progressive tempo increase is on
    //  we want to go back to the original tempo
    if (progressiveTempoIncrease) {
      setTempo(originalTempo);
    }
  };

  return (
    <Fragment>
      <RandomNote
        isRunning={isRunning}
        currentBeat={currentBeat}
        countdown={countdown}
        currentBar={currentBar}
        tempo={tempo}
        setTempo={setTempo}
        onBeat={onBeat}
        onStart={onStart}
        onStop={onStop}
        volume={volume}
        setVolume={setVolume}
        subdivision={subdivision}
        setSubdivision={setSubdivision}
        noteType={noteType}
        setNoteType={setNoteType}
        progressiveTempoIncrease={progressiveTempoIncrease}
        setProgressiveTempoIncrease={onProgressiveTempoIncreaseChange}
        tonalMetronome={tonalMetronome}
        setTonalMetronome={setTonalMetronome}
      />
    </Fragment>
  );
}

export default App;
